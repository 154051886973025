import {
  GridWithGaps,
  HeadingH1,
  HeadingH2,
} from '@dreamstack/feature-components'
import type {
  PersonAccordionEntryFragment,
  PersonAccordionFragment,
} from '@dreamstack/investors-graphql'
import first from 'lodash/first'
import map from 'lodash/map'
import type { FunctionComponent} from 'react';
import { Fragment, useRef, useState } from 'react'
import 'twin.macro'
import tw, { styled } from 'twin.macro'
import { ContentfulBlock } from '../contentful/ContentfulBlock'

const EntryContainer = styled.div(({ isOpen }: { isOpen: boolean }) => [
  tw`col-span-2`,
  !isOpen && tw`hidden`,
])

const PersonTemplate: FunctionComponent<React.PropsWithChildren<{
  personEntry: any
  isOpen: any
  setOpenEntryId: any
  id: any
}>> = ({ personEntry, isOpen, setOpenEntryId, id }) => {
  const personTemplateRef = useRef<HTMLDivElement | null>(null)
  const scrollToRef = (ref: typeof personTemplateRef) => {
    ref?.current?.scrollIntoView({ block: 'center', behavior: 'auto' })
  }
  return (
    <Fragment key={personEntry.sys.id}>
      {!isOpen && (
        <HeadingH2
          tw="col-span-12 md:col-span-6 md:col-start-7 text-accentroAqua-full cursor-pointer mb-px-24"
          onClick={() => {
            setOpenEntryId(id)
            setTimeout(() => scrollToRef(personTemplateRef), 50)
          }}
        >
          {personEntry.fullName}
        </HeadingH2>
      )}
      <div tw="col-span-12" ref={personTemplateRef}>
        <EntryContainer isOpen={isOpen}>
          <ContentfulBlock block={personEntry as any} />
        </EntryContainer>
      </div>
    </Fragment>
  )
}

export const PersonAccordion: FunctionComponent<React.PropsWithChildren<{
  block: PersonAccordionFragment & {
    childBlockData: PersonAccordionEntryFragment[]
  }
}>> = ({ block }) => {
  const persons = block?.childBlockData
  const [openEntryId, setOpenEntryId] = useState(
    first(map(persons, (person) => person?.sys.id))
  )

  return (
    <GridWithGaps>
      <HeadingH1 tw="my-px-48 md:my-px-80 col-span-12 lg:(my-px-96)">
        {block.title}
      </HeadingH1>
      {map(persons, (personEntry) => {
        const isOpen = personEntry?.sys.id === openEntryId
        const id = personEntry?.sys.id

        return (
          <PersonTemplate
            personEntry={personEntry}
            isOpen={isOpen}
            setOpenEntryId={setOpenEntryId}
            id={id}
            key={id}
          />
        )
      })}
    </GridWithGaps>
  )
}
